import { useState, useEffect, useCallback } from "react"
import { getDatabase, ref, set, get, onValue, DataSnapshot, update } from "firebase/database"
import { getAuth, signInAnonymously } from "firebase/auth"

import { Account8 } from "seenspire-api-client"
import { useUserAgent } from "../../hooks/useUserAgent"

export interface MonitorsData {
  [key: string]: Monitor
}

export interface Monitor {
  uid: string
  name: string
  accountSlug: string
  feedUrl?: string
  feedName?: string
  status?: string
  createdAt?: string
  deviceType?: string
}


interface UseFirebaseAnonReturnType {
  data: Monitor[] | null
  loading: boolean
  initialLoadComplete: boolean
  error: string | null
  uid: string | null
  getMonitor: (device: string) => Promise<Monitor | null>
  removeMonitor: (device: string) => Promise<void>
  getDevices: () => Promise<void>
  setMonitor: (deviceCode: string, deviceName: string) => Promise<Monitor | void>
  setFeed: (url: string, streamName: string, deviceId: string) => Promise<void>
}

const useFirebaseAnon = (account: Account8): UseFirebaseAnonReturnType => {

  const [data, setData] = useState<Monitor[] | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(true);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [uid, setUid] = useState<string | null>(null)

  const {parse} = useUserAgent();

  const getDevices = useCallback(async () => {

    if (!account) return;

    const auth = getAuth()

    const authenticateAnonymously = async () => {

      try {
        const user = await signInAnonymously(auth)

        const realtimeUuid = account?.realtime_id;

        if (user && user.user && user.user.uid && realtimeUuid) {

          setUid(realtimeUuid)
        }
      } catch (error: any) {

        setError(error ? error.message : error)
        setLoading(false)
      }
    };

    if (!auth.currentUser) {

      await authenticateAnonymously()
    }

    const realtimeUuid = account?.realtime_id;
    setUid(realtimeUuid ?? null)

  }, [account])

  useEffect(() => {

    getDevices();
  }, [getDevices])

  useEffect(() => {
    if (!account) return;

    if (!uid || uid.trim() === "") {
      setError('Invalid or empty UID provided. - 1')
      return undefined
    }

    const db = getDatabase();
    const mainRef = ref(db, `mobile/accounts_new/${uid}`);

    const secondaryListeners: { [key: string]: () => void } = {};
    let pendingSecondaryLoads = 0;

    const handleValueChange = (snapshot: DataSnapshot | null) => {
      const mainData = (snapshot ? snapshot.val() : {}) as MonitorsData;
      const mixedData = { ...mainData };

      const updateSecondaryData = (key: string, secondaryData: any) => {
        const ua = parse(secondaryData?.ua);
        mixedData[key] = {
          ...mixedData[key],
          status: secondaryData.status,
          deviceType: ua.browser && ua.device ? `${ua.os}, ${ua.browser}` : "-",
        };

        const monitors = Object.entries(mixedData)
          .filter(([k, v]) => k !== 'zoom' && v)
          .map(([_, v]) => v);

        if (mixedData?.zoom) {
          Object.values(mixedData.zoom).forEach(v => monitors.push(v));
        }

        const filtered = monitors.sort((a, b) => b.name > a.name ? -1 : 1);
        setData(filtered);
        
        pendingSecondaryLoads--;
        if (pendingSecondaryLoads === 0 && !initialLoadComplete) {
          setInitialLoadComplete(true);
          setLoading(false);
        }

      };

      // Remove old listeners
      Object.values(secondaryListeners).forEach(unsubscribe => unsubscribe());

      pendingSecondaryLoads = 0;

      if (mainData) {
        // Set up new listeners
        Object.entries(mainData).forEach(([key, value]) => {
          if (value?.uid) {
            pendingSecondaryLoads++;
            const secondaryRef = ref(db, `mobile/connect/${value.uid}`);
            const unsubscribe = onValue(secondaryRef, (secondarySnapshot) => {
              const secondaryData = secondarySnapshot.val();
              updateSecondaryData(key, secondaryData);
            });
            secondaryListeners[key] = unsubscribe;
          }
        });
      } else {
        setData(null);
      }

      if (pendingSecondaryLoads === 0 && !initialLoadComplete) {
        setInitialLoadComplete(true);
        setLoading(false);
      }
    };

    const unsubscribeMain = onValue(mainRef, handleValueChange);

    return () => {
      unsubscribeMain();
      Object.values(secondaryListeners).forEach(unsubscribe => unsubscribe());
    };
  }, [uid, account]);

  const setMonitor = useCallback(async (deviceCode: string, deviceName: string): Promise<Monitor | void> => {

    const db = getDatabase()
    try {
      const deviceCodeRef = ref(db, `mobile/codes/${deviceCode}`);
      const snapshot = await get(deviceCodeRef);
      if (snapshot.exists()) {
        const deviceUid = snapshot.val();  
        
        try {
          const poolRef = ref(db, `mobile/pool/${deviceUid}`)
          await set(poolRef, { uid: deviceUid, name: deviceName, accountSlug: account.organization_info.slug, createdAt: new Date().toISOString() })
          const accRef = ref(db, `mobile/accounts_new/${uid}/${deviceUid}`)
          await set(accRef, { uid: deviceUid, name: deviceName, accountSlug: account.organization_info.slug, createdAt: new Date().toISOString() })


          setData(prev => [...(prev ?? []), { uid: deviceUid, name: deviceName, accountSlug: account.organization_info.slug, createdAt: new Date().toISOString() }])

          return { uid: deviceUid, name: deviceName, accountSlug: account.organization_info.slug, createdAt: new Date().toISOString() }
        } catch (error) {
          if (error instanceof Error && error.message.includes('Permission denied')) {
            throw new Error('You do not have permission to add this screen. Please contact your administrator.');
          }
          throw error; // Re-throw other errors
        }
      } else {
        throw new Error('Invalid screen code. Please check and try again.');
      }
    } catch (error) {
      if (error instanceof Error && error.message.includes('Permission denied')) {
        throw new Error('You do not have permission to read screen codes. Please contact your administrator.');
      }
      throw error; // Re-throw other errors
    }
  }, [uid, account])

  const getMonitor = useCallback(async (device: string): Promise<Monitor | null> => {
    
    if (!account) return null;

    const isCode = device.length === 6
    
    const db = getDatabase()
    const rtdbRef = ref(db, isCode ? `mobile/codes/${device}` : `mobile/pool/${device}`)
    const snapshot = await get(rtdbRef)
    
    let monitor = snapshot ? snapshot.val() : null
    
    if (isCode && monitor) {
        const rtdbRef2 = ref(db, `mobile/pool/${monitor}`)
        const snapshot2 = await get(rtdbRef2)
        monitor = snapshot2 ? snapshot2.val() : null
    }

    if (!monitor) {
      throw new Error('Monitor is not found.')
    }

    return monitor;
  }, [uid, account])

  const removeMonitor = useCallback(async (device: string): Promise<void> => {

    if (!account) return;

    const isCode = device.length === 6

    if (uid) {
      const db = getDatabase()

      let deviceId = device
      if (isCode) {
        const deviceCodeRef = ref(db, `mobile/codes/${device}`);
        const snapshot = await get(deviceCodeRef);
        if (snapshot.exists()) {
          deviceId = snapshot.val();  
        }
        await set(deviceCodeRef, null)
      }

      const poolRef = ref(db, `mobile/pool/${deviceId}`)
      await set(poolRef, null)

      const accRef = ref(db, `mobile/accounts_new/${uid}/${deviceId}`)
      await set(accRef, null)

    } else {
      throw new Error('Invalid or empty UID provided. - 3')
    }
  }, [uid, account, data])

  const setFeed = useCallback(async (url: string, streamName: string, deviceId: string): Promise<void> => {
    if (uid) {
      const db = getDatabase()
      const poolRef = ref(db, `mobile/pool/${deviceId}`)
      await update(poolRef, { feedUrl: url, feedName: streamName })
      const accRef = ref(db, `mobile/accounts_new/${uid}/${deviceId}`)
      await update(accRef,  { feedUrl: url, feedName: streamName, accountSlug: account.organization_info.slug, uid: deviceId, createdAt: new Date().toISOString() })
    } else {
      throw new Error('Invalid or empty UID provided. - 4')
    }
  }, [uid])

  return { uid, data, loading, error, getMonitor, removeMonitor, getDevices, initialLoadComplete, setMonitor, setFeed }
}

export default useFirebaseAnon
